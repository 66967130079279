$milk: #ffffff;

$black: #303030;
$blackLight: #707070;

$grey: #858c9b;
$greyLight: #bbc1ce;
$greyLighter: #f5f5f5;
$greyLightest: #fafafa;

$error50: #FFF6F3;
$error200: #FDB4AC;
$error300: #FB8282;
$error500: #F33055;
$error600: #D02354;
$error700: #AE1851;
$error800: #8C0F4A;

$matcha: #12a25f;
$matchaLighter: #a0dabf;
$matchaLight: #59be8f;
$matchaLightest: #e6f7ef;
$matchaDark: #0e824c;
$matchaDarker: #0b6139;
$matchaDarkest: #074126;

$honey: #fab300;
$honeyLight: #ffc100;
$honeyLighter: #fde199;
$honeyLightest: #fff7e5;
$honeyDark: #cc9200;
$honeyDarker: #a37500;
$honeyDarkest: #644800;

$mentai: #f56200;
$mentaiLight: #ff8533;
$mentaiLighter: #ffc299;
$mentaiLightest: #fff3eb;
$mentaiDark: #cc5200;
$mentaiDarker: #994f1f;
$mentaiDarkest: #663514;

$aqua: #007af5;
$aquaLight: #3392ff;
$aquaLighter: #70b3ff;
$aquaLightest: #ebf4ff;
$aquaDark: #005fcc;
$aquaDarker: #00438f;
$aquaDarkest: #003066;

$skin: #f0d9c4;
$nose: #908376;
$clear: #f5faff;

$overlay10: rgba(0, 0, 0, 0.1);
$overlay40: rgba(0, 0, 0, 0.4);
$overlay60: rgba(0, 0, 0, 0.6);
$overlay80: rgba(0, 0, 0, 0.8);
$overlay90: rgba(0, 0, 0, 0.9);

$whiteOverlay20: rgba(255, 255, 255, 0.2);
$whiteOverlay40: rgba(255, 255, 255, 0.4);
$whiteOverlay60: rgba(255, 255, 255, 0.6);
$whiteOverlay80: rgba(255, 255, 255, 0.8);
$whiteOverlay90: rgba(255, 255, 255, 0.9);

$reddish: linear-gradient(135deg, #de2b4c 0%, #bc1433 64.05%);
$reddishLoyalty: linear-gradient(180deg, #de2b4c 0%, #bc1433 100%);

$eggplant: #635cec;
$lime: #15ad49;
$ketchup: #d82647;

$limeOverlay05: rgba($lime, 0.05);
$limeOverlay10: rgba($lime, 0.1);
$overlay05: rgba(0, 0, 0, 0.05);

$upShadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
$downShadow: 0 4px 10px rgba(0, 0, 0, 0.05);

:export {
	milk: $milk;

	black: $black;
	blackLight: $blackLight;

	grey: $grey;
	greyLight: $greyLight;
	greyLighter: $greyLighter;
	greyLightest: $greyLightest;

	error50: $error50;
	error200: $error200;
	error300: $error300;
	error500: $error500;
	error600: $error600;
	error700: $error700;
	error800: $error800;

	matcha: $matcha;
	matchaLighter: $matchaLighter;
	matchaLight: $matchaLight;
	matchaLightest: $matchaLightest;
	matchaDark: $matchaDark;
	matchaDarker: $matchaDarker;
	matchaDarkest: $matchaDarkest;

	honey: $honey;
	honeyLight: $honeyLight;
	honeyLighter: $honeyLighter;
	honeyLightest: $honeyLightest;
	honeyDark: $honeyDark;
	honeyDarker: $honeyDarker;
	honeyDarkest: $honeyDarkest;

	mentai: $mentai;
	mentaiLight: $mentaiLight;
	mentaiLighter: $mentaiLighter;
	mentaiLightest: $mentaiLightest;
	mentaiDark: $mentaiDark;
	mentaiDarker: $mentaiDarker;
	mentaiDarkest: $mentaiDarkest;

	aqua: $aqua;
	aquaLight: $aquaLight;
	aquaLighter: $aquaLighter;
	aquaLightest: $aquaLightest;
	aquaDark: $aquaDark;
	aquaDarker: $aquaDarker;
	aquaDarkest: $aquaDarkest;

	skin: $skin;
	nose: $nose;
	clear: $clear;

	overlay10: $overlay10;
	overlay40: $overlay40;
	overlay60: $overlay60;
	overlay80: $overlay80;
	overlay90: $overlay90;

	whiteOverlay20: $whiteOverlay20;
	whiteOverlay40: $whiteOverlay40;
	whiteOverlay60: $whiteOverlay60;
	whiteOverlay80: $whiteOverlay80;
	whiteOverlay90: $whiteOverlay90;

	reddish: $reddish;
	reddishLoyalty: $reddishLoyalty;

	eggplant: $eggplant;
	lime: $lime;
	ketchup: $ketchup;

	limeOverlay05: $limeOverlay05;
	limeOverlay10: $limeOverlay10;
	overlay05: $overlay05;

	upShadow: $upShadow;
	downShadow: $downShadow;
}
