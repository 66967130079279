@import "src/assets/styles/animations.module";
@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/mixins.module";
@import "src/assets/styles/fonts.module";

.NavigationBar {
  z-index: 2;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: pxToVwMobileMaxed(24px);
  width: pxToVwMobileMaxed(286px);
  height: pxToVwMobileMaxed(40px);
  padding: 0 pxToVwMobileMaxed(4px);
  border-radius: pxToVwMobileMaxed(12px);
  box-sizing: border-box;
  @include backdrop-blur;

  @media (min-width: 860px) {
    height: pxToVwMobileMaxed(32px);
    top: pxToVwMobileMaxed(16px);
    left: pxToVwMobileMaxed(98px);
    right: unset;
    bottom: unset;
  }

  @media (min-width: 1180px) {
    left: 0;
    right: 0;
  }

  &__bgSliderContainer {
    top: 0;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    width: calc(100% - pxToVwMobileMaxed(8px));
  }
  &__activeLinkBg {
    width: 33%;
    position: absolute;
    transition-duration: 500ms;
    height: calc(100% - pxToVwMobileMaxed(8px));
    border-radius: pxToVwMobileMaxed(12px);
    background-color: $eggplant;

    @media (min-width: 860px) {
      border-radius: pxToVwMobileMaxed(8px);
    }
  }
  &__linkContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    li {
      width: 100%;
      color: $black;
      display: flex;
      flex-shrink: 1;
      cursor: pointer;
      list-style: none;
      align-items: center;
      justify-content: center;
      transition-duration: 250ms;
      font-size: pxToVwMobileMaxed(16px);
      line-height: pxToVwMobileMaxed(24px);
      border-radius: pxToVwMobileMaxed(12px);
      @include font-regular;
    }
    &--active {
      color: $milk !important;
    }
  }
}
