@import "./others.module";

@mixin font-extra-bold {
	font-weight: 800;
	font-family: 'Rubik', sans-serif;
}

@mixin font-bold {
	font-weight: 700;
	font-family: 'Rubik', sans-serif;
}

@mixin font-medium {
	font-weight: 500;
	font-family: 'Rubik', sans-serif;
}

@mixin font-regular {
	font-weight: 400;
	font-family: 'Rubik', sans-serif;
}


// Heading
@mixin heading-h1-64 {
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  font-size: pxToVwMobileMaxed(64px);
  line-height: pxToVwMobileMaxed(80px);
}
@mixin heading-h2-48 {
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  font-size: pxToVwMobileMaxed(48px);
  line-height: pxToVwMobileMaxed(60px);
}
@mixin heading-h3-32 {
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  font-size: pxToVwMobileMaxed(32px);
  line-height: pxToVwMobileMaxed(40px);
}
@mixin heading-h4-24 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(24px);
  line-height: pxToVwMobileMaxed(32px);
}
@mixin heading-h5-20 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(20px);
  line-height: pxToVwMobileMaxed(28px);
}
@mixin heading-h6-18 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(18px);
  line-height: pxToVwMobileMaxed(26px);
}

// Title
@mixin title-bold-20 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(20px);
  line-height: pxToVwMobileMaxed(28px);
}
@mixin title-bold-18 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(18px);
  line-height: pxToVwMobileMaxed(26px);
}
@mixin title-bold-16 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(16px);
  line-height: pxToVwMobileMaxed(24px);
}
@mixin title-bold-14 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(14px);
  line-height: pxToVwMobileMaxed(20px);
}

// Subtitle
@mixin subtitle-medium-20 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(20px);
  line-height: pxToVwMobileMaxed(28px);
}
@mixin subtitle-medium-18 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(18px);
  line-height: pxToVwMobileMaxed(26px);
}
@mixin subtitle-medium-16 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(16px);
  line-height: pxToVwMobileMaxed(24px);
}
@mixin subtitle-medium-14 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(14px);
  line-height: pxToVwMobileMaxed(20px);
}

// Body
@mixin body-regular-20 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(20px);
  line-height: pxToVwMobileMaxed(28px);
}
@mixin body-regular-18 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(18px);
  line-height: pxToVwMobileMaxed(26px);
}
@mixin body-regular-16 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(16px);
  line-height: pxToVwMobileMaxed(24px);
}
@mixin body-regular-14 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(14px);
  line-height: pxToVwMobileMaxed(20px);
}

// Caption
@mixin caption-bold-12 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(12px);
  line-height: pxToVwMobileMaxed(16px);
}
@mixin caption-medium-12 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(12px);
  line-height: pxToVwMobileMaxed(16px);
}
@mixin caption-regular-12 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(12px);
  line-height: pxToVwMobileMaxed(16px);
}

// Footer
@mixin footer-bold-10 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(10px);
  line-height: pxToVwMobileMaxed(12px);
}
@mixin footer-medium-10 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(10px);
  line-height: pxToVwMobileMaxed(12px);
}
@mixin footer-regular-10 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: pxToVwMobileMaxed(10px);
  line-height: pxToVwMobileMaxed(12px);
}

// Label
@mixin label-bold-20 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(20px);
  line-height: pxToVwMobileMaxed(28px);
  letter-spacing: pxToVwMobileMaxed(1.25px);
  text-transform: uppercase;
}
@mixin label-bold-12 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: pxToVwMobileMaxed(12px);
  line-height: pxToVwMobileMaxed(18px);
  letter-spacing: pxToVwMobileMaxed(1.25px);
  text-transform: uppercase;
}

// Promo
@mixin promo-medium-12 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: pxToVwMobileMaxed(12px);
  line-height: pxToVwMobileMaxed(18px);
  text-decoration-line: line-through;
}
