@import "src/assets/styles/others.module";

.currencyShort {
  display: flex;
  align-items: center;
  img {
    width: pxToVwMobileMaxed(16px);
    margin-right: pxToVwMobileMaxed(4px);
  }
}
.currencyLong {
  @extend .currencyShort;
  img {
    margin-right: pxToVwMobileMaxed(8px);
  }
}

.CurrencyDropdown {
  width: pxToVwMobileMaxed(88px);
  &__mainLabelContainer {
    height: 100%;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  &__dropdown {
    left: 0;
    width: pxToVwMobileMaxed(184px);
  }
  &__chevronIcon {
    font-size: pxToVwMobileMaxed(12px);
  }
}
