@import "src/assets/styles/colors.module";

@mixin backdrop-blur {
  background-color: $whiteOverlay80;

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: $whiteOverlay60;
    backdrop-filter: blur(40px);
  }
}
