@use "sass:math";

$enableScaling: true;
$mobileBreakPoint: 425px;
$designWidthMobile: 360px;
$designWidthDesktop: 1308px;

@function inject-px($number) {
	@if type-of($number) == "number" and unitless($number) {
		@return $number * 1px;
	}
	@return $number;
}

// DESKTOP SCALING
@function pxToVw($pxValue) {
	@return math.div($pxValue, $designWidthDesktop) * 100vw;
}

@function pxToVwMaxed($pxValue) {
	$pxValue: inject-px($pxValue);
	@if $enableScaling {
		@if $pxValue < 0 {
			@return max(#{pxToVw($pxValue)}, #{$pxValue});
		}
		@return min(#{pxToVw($pxValue)}, #{$pxValue});
	}
	@return $pxValue;
}

// MOBILE SCALING
@function mobileBreakPointScaling($pxValue) {
	@return math.div($pxValue, $designWidthMobile) * $mobileBreakPoint;
}

@function pxToVwMobile($pxValue) {
	@return math.div($pxValue, $designWidthMobile) * 100vw;
}

@function pxToVwMobileMaxed($pxValue) {
	$pxValue: inject-px($pxValue);
	@if $enableScaling {
		@if $pxValue < 0 {
			@return max(#{pxToVwMobile($pxValue)}, #{mobileBreakPointScaling($pxValue)});
			// @return max(#{pxToVwMobile($pxValue)}, #{$pxValue});
		}
		@return min(#{pxToVwMobile($pxValue)}, #{mobileBreakPointScaling($pxValue)});
		// @return min(#{pxToVwMobile($pxValue)}, #{$pxValue});
	}
	@return $pxValue;
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	designWidthMobile: $designWidthMobile;
	designWidthDesktop: $designWidthDesktop;
}
