@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/mixins.module";
@import "src/assets/styles/fonts.module";

.HeaderBar {
  z-index: 2;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  flex-direction: row;
  height: pxToVwMobileMaxed(56px);
  justify-content: space-between;

  @media (min-width: 860px) {
    height: pxToVwMobileMaxed(64px);
  }

  &__webAppIcon {
    position: relative;
    margin-left: pxToVwMobileMaxed(16px);
    height: pxToVwMobileMaxed(32px);
  }
  &__rightActionBar {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    padding-right: pxToVwMobileMaxed(16px);
  }
  &__dropdownGap {
    margin-left: pxToVwMobileMaxed(4px);
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    @include backdrop-blur;
    opacity: 0;
    transition: opacity 250ms;
    &--scrolled {
      opacity: 1;
    }
  }
}
