@import "src/assets/styles/colors.module";

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: url("assets/images/background-desktop.jpg");
  background-repeat: no-repeat;
  background-size: 100vw auto;
	background-position: top center;
  background-attachment: fixed;
}

@media (max-width: 1024px) {
	body {
		background-image: url("assets/images/background.jpg");
  }
}

#root {
  width: 100%;
  margin: auto;
  max-width: 512px;
  min-height: 100vh;
	position: relative;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
