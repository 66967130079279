@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.Button {
  $accentColor: $eggplant;
  
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: pxToVwMobileMaxed(32px);
  border-radius: pxToVwMobileMaxed(12px);
  transition: background-color 0.3s;

  // to remove button click highlight color, IOS workarounds using transparency
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
  &__content {
    display: flex;
    align-items: center;
  }
  &__contained {
    color: $milk;
    background-color: $accentColor;
    border: 0;
    &:disabled {
      background-color: $greyLight;
    }
  }

  &__outlined {
    color: $accentColor;
    border: 1px solid $accentColor;
    background-color: transparent;
    &:disabled {
      color: $greyLight;
      border: 1px solid $greyLight;
    }
  }
  
  &__ghost {
    color: $accentColor;
    border: 0;
    background-color: transparent;
    &:disabled {
      color: $greyLight;
    }
  }

  &__size {
    &--small {
      @include caption-regular-12;
      height: pxToVwMobileMaxed(32px);
    }
    &--medium {
      @include body-regular-16;
      height: pxToVwMobileMaxed(40px);
    }
    &--large {
      @include body-regular-16;
      height: pxToVwMobileMaxed(48px);
    }
  }

  &__loading {
    margin-left: pxToVwMobileMaxed(8px);
  }
}
