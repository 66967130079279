@import "src/assets/styles/others.module";
@import "src/assets/styles/fonts.module";
@import "src/assets/styles/colors.module";

.AccountDropdown {
  &__label {
    display: flex;
    align-items: center;
    max-width: pxToVwMobileMaxed(120px);
    &__initial {
      margin-right: pxToVwMobileMaxed(6px);
      width: pxToVwMobileMaxed(16px);
      height: pxToVwMobileMaxed(16px);
      border-radius: 50%;
      background-color: $mentaiDark;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-size: pxToVwMobileMaxed(10px);
      @include font-medium;
      color: $milk;
    }
    p {
      flex: 1;
      @include body-regular-16;
      color: $black;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }
  &__dropdown {
    right: 0;
    min-width: pxToVwMobileMaxed(182px);
    li p {
      white-space: nowrap;
    }
    &__actionIcon {
      color: $black !important;
      font-size: pxToVwMobileMaxed(14px);
      margin-right: pxToVwMobileMaxed(10px);
    }
  }
}
