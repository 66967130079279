@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.PasswordConfirmationInput {
  &__formGap {
    margin-top: pxToVwMobileMaxed(12px);
  }
  &__validationContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: pxToVwMobileMaxed(4px);
    &__row {
      transition-duration: 250ms;
      @include caption-regular-12;
      color: $greyLight;
      svg {
        margin-right: pxToVwMobileMaxed(4px);
      }
      &--valid {
        color: $lime;
      }
    }
  }
}
