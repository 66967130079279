@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.Input {
  &__title {
    @include caption-regular-12;
    color: $blackLight;
    margin-bottom: pxToVwMobileMaxed(4px);
  }
  &__inputField {
    $inputField: &; // store class name in variable
    position: relative;
    padding: 0;
    height: pxToVwMobileMaxed(48px);
    overflow: hidden;
    display: flex;
    align-items: center;
    transition-duration: 250ms;
    border: 1px solid $overlay10;
    &__leftRender {
      padding-left: pxToVwMobileMaxed(12px);
      &__icon {
        font-size: pxToVwMobileMaxed(16px);
        margin-left: pxToVwMobileMaxed(4px);
        color: $blackLight;
        transition: color 250ms ease-in-out;
        &--black {
          color: $black;
        }
      }
    }
    &__input {
      @include body-regular-14;
      color: $black;
      background-color: transparent;
      padding: 0 pxToVwMobileMaxed(12px);
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 0;
      outline: none;
      &::placeholder {
        color: $greyLight;
      }
      &--rightPadding {
        padding-right: pxToVwMobileMaxed(44px);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active,
      &:-webkit-autofill::first-line {
        -webkit-text-fill-color: $black;
        transition: background-color 5000s ease-in-out 0s; // remove autofill background
      }
    }
    &__clearButton {
      position: absolute;
      right: pxToVwMobileMaxed(16px);
      font-size: pxToVwMobileMaxed(16px);
      color: $black;
    }
    &--focused {
      border-color: $eggplant;
    }
    &--disabled {
      background-color: $greyLighter;
      // reuse inputField class name, as ampersand parses to --disabled__leftRender
      #{$inputField}__leftRender {
        &__icon {
          color: $grey;
        }
      }
      #{$inputField}__input {
        color: $grey;
        -webkit-text-fill-color: $grey; /* required on iOS */
        opacity: 1; /* required on iOS */
      }
    }
    &--error {
      border-color: $ketchup;
    }
  }
  &__error {
    margin-top: pxToVwMobileMaxed(4px);
    @include caption-regular-12;
    color: $ketchup;
    overflow: hidden;
  }
}
