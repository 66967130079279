@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.AccountDetailModal {
	&__content {
		&__inputFullName {
			margin-bottom: pxToVwMobileMaxed(16px);
			input {
				text-transform: capitalize;
			}
		}
	}
	&__footer {
		display: flex;
		align-items: center;
		svg {
			color: $blackLight;
		}
		p {
			color: $blackLight;
			margin-left: pxToVwMobileMaxed(10px);
			@include caption-regular-12();
		}
	}
}
