@import "src/assets/styles/others.module";

.touchable-opacity {
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.touchable-opacity:active {
  opacity: 0.7;
}

.disable-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.markdown-container {
  white-space: pre-wrap;
  ul, ol {
    white-space: normal;
    margin: pxToVwMobileMaxed(-4px) 0;
    padding-left: pxToVwMobileMaxed(16px);
    li {
	    p {
        display: inline-block;
      }
    }
  }
}
