@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/mixins.module";
@import "src/assets/styles/fonts.module";

.Card {
	position: relative;
	border-style: solid;
	box-sizing: border-box;
	border-color: $whiteOverlay40;
	padding: 0 pxToVwMobileMaxed(7px);
	border-width: 1px;
	border-radius: pxToVwMobileMaxed(12px);
	@include backdrop-blur;
	&--shadow {
		box-shadow: $downShadow;
	}
}

