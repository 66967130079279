@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";

.languageLabel {
  display: flex;
  align-items: center;
  img {
    width: pxToVwMobileMaxed(16px);
    margin-right: pxToVwMobileMaxed(8px);
  }
}

.LanguageDropdown {
  min-width: pxToVwMobileMaxed(32px);
  &__mainLabelContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__globeIcon {
    display: flex;
    align-self: center;
    transition-duration: 300ms;
    font-size: pxToVwMobileMaxed(16px);
    &--active {
      color: $eggplant;
    }
  }
  &__dropdown {
    right: 0;
    width: pxToVwMobileMaxed(164px);
  }
}
