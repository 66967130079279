@import "src/assets/styles/others.module";
@import "src/assets/styles/fonts.module";
@import "src/assets/styles/colors.module";

.EditPasswordModal {
  max-height: unset;
  &__oldPassword {
    padding-bottom: pxToVwMobileMaxed(16px);
    margin-bottom: pxToVwMobileMaxed(16px);
    border-bottom: 1px dashed $greyLighter;
  }
  &__footer {
    &__button {
      width: 100%;
    }
  }
}
