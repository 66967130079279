@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.TransitionRoute {
	min-height: 100vh;
	position: relative;
	box-sizing: border-box;
  &__header {
    padding-top: pxToVwMobileMaxed(64px);

    @media (min-width: 860px) {
      padding-top: pxToVwMobileMaxed(120px);
    }
  }
  &__footer {
    padding-bottom: pxToVwMobileMaxed(80px);
  }
}
