@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.Toaster {
  inset: pxToVwMobileMaxed(12px) pxToVwMobileMaxed(16px) !important;
  &__toast {
    display: flex;
    background-color: $milk;
    padding: pxToVwMobileMaxed(8px);
    border-radius: pxToVwMobileMaxed(12px);
    box-sizing: border-box;
    width: calc(512px - pxToVwMobileMaxed(32px));
    box-shadow: $downShadow;
    max-width: unset;
    & > :first-child {
      // Override default toast component's direct child wrapper
      margin: 0;
    }
    &__content {
      width: 100%;
      display: flex;
      &__middle {
        flex: 1;
        &__title {
          @include caption-bold-12;
          color: $black;
        }
        &__description {
          margin-top: pxToVwMobileMaxed(2px);
          @include caption-regular-12;
          color: $blackLight;
        }
      }
      &__leftIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: pxToVwMobileMaxed(18px);
        height: pxToVwMobileMaxed(18px);
        margin-right: pxToVwMobileMaxed(8px);
        font-size: pxToVwMobileMaxed(14px);
      }
      &__closeButton {
        @extend .Toaster__toast__content__leftIcon;
        margin-left: pxToVwMobileMaxed(8px);
        margin-right: 0;
        color: $blackLight;
      }
    }
  }
}
