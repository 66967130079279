@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

.Dropdown {
  $dropdownTransition: 300ms;
  height: pxToVwMobileMaxed(32px);
  transition-duration: $dropdownTransition;
  &--active {
    background-color: $milk;
  }
  &__mainLabelContainer {
    height: 100%;
    color: $black;
    cursor: pointer;
    font-size: pxToVwMobileMaxed(16px);
    line-height: pxToVwMobileMaxed(24px);
    &__defaultText {
      color: $black;
      display: inline-block;
      vertical-align: middle;
      @include body-regular-16;
    }
  }
  &--visible {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0) !important;
  }
  &__listOuterContainer {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    padding-top: pxToVwMobileMaxed(8px);
    transition-duration: $dropdownTransition;
    transform: translateY(pxToVwMobileMaxed(6px));
  }
  &__listInnerContainer {
    background-color: $milk;
    padding: pxToVwMobileMaxed(16px) !important;
    &__headLabel {
      display: block;
      color: $blackLight;
      @include body-regular-16;
      margin-bottom: pxToVwMobileMaxed(4px);
    }
    li {
      color: $black;
      display: flex;
      cursor: pointer;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      @include subtitle-medium-16;
      margin-bottom: pxToVwMobileMaxed(8px);
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        color: $lime;
        font-size: pxToVwMobileMaxed(14px);
      }
    }
  }
}
