@import "src/assets/styles/animations.module";
@import "src/assets/styles/others.module";
@import "src/assets/styles/colors.module";
@import "src/assets/styles/fonts.module";

%fixedStyles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.Modal {
  @extend %fixedStyles;
  // 100vh ignores the address bar, overlay must pass through the address bar to make sure overlay covers everything when the address bar hides itself
  height: 100vh;
  z-index: 999;
  background-color: $overlay80;
  &__cardContainer {
    pointer-events: visible;
    @extend %fixedStyles;
    // 100% height respects address bar, modal card uses this to center itself vertically adapting to address bar shown/hidden
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: pxToVwMobileMaxed(24px) pxToVwMobileMaxed(16px);
  }
  &__card {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-color: $milk;
    max-width: 448px;
    max-height: pxToVwMobileMaxed(466px);
    border-radius: pxToVwMobileMaxed(12px);
  }
  &__header {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    border-bottom-style: solid;
    background-color: $greyLightest;
    border-bottom-color: $overlay10;
    padding: pxToVwMobileMaxed(16px);
    min-height: pxToVwMobileMaxed(56px);
    border-bottom-style: solid;
    border-bottom-color: $overlay10;
    border-bottom-width: pxToVwMobileMaxed(1px);
    @include title-bold-16;
    border-top-left-radius: pxToVwMobileMaxed(
      12px
    ); // iOS bug: safari ignores parent's border-radius
    border-top-right-radius: pxToVwMobileMaxed(
      12px
    ); // iOS bug: safari ignores parent's border-radius
    &__custom {
      min-height: unset;
    }
    &__closeButton {
      padding: 0;
      border: none;
      cursor: pointer;
      position: absolute;
      top: pxToVwMobileMaxed(16px);
      background-color: transparent;
      right: pxToVwMobileMaxed(16px);
      width: pxToVwMobileMaxed(24px);
      height: pxToVwMobileMaxed(24px);
      svg {
        color: $black;
        font-size: pxToVwMobileMaxed(16px);
      }
    }
  }
  &__content {
    overflow: auto;
    box-sizing: border-box;
    padding: pxToVwMobileMaxed(16px);
  }
  &__footer {
    box-sizing: border-box;
    border-top-style: solid;
    border-top-color: $overlay10;
    background-color: $greyLightest;
    padding: pxToVwMobileMaxed(16px);
    border-top-style: solid;
    border-top-color: $overlay10;
    border-top-width: pxToVwMobileMaxed(1px);
  }
}