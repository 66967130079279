.Animation {
  &--fadeIn {
    animation: fadeIn 250ms forwards;
  }
  &--fadeOut {
    animation: fadeOut 250ms forwards;
  }
  &--slideIn {
    animation: slideIn 250ms forwards;
  }
  &--slideOut {
    animation: slideOut 250ms forwards;
  }
  &--heightIn80 { // Need to define max-height as it's not possible to be dynamic
    animation: heightIn80 500ms forwards;
  }
  &--heightOut80 { // Need to define max-height as it's not possible to be dynamic
    animation: heightOut80 500ms forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-24px);
  }
}

@keyframes slideOut {
  to {
    transform: translateY(-24px);
  }
}

@keyframes heightIn80 {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 80px;
    opacity: 1;
  }
}

@keyframes heightOut80 {
  from {
    max-height: 80px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}
